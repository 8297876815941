import employee_joh from '../images/employee_joh.jpg'
import employee_hej from '../images/employee_hej.jpg'
import employee_jha from '../images/employee_jha.jpg'
import employee_jkr from '../images/employee_jkr.jpg'
import employee_rih from '../images/employee_rih.jpg'
import employee_soj from '../images/employee_soj.jpg'
import employee_jbs from '../images/employee_jbs.jpg'
import employee_her from '../images/employee_her.jpg'

const employees = [
  {
    name: 'Henrik Jensen',
    title: 'CEO & Founder',
    image: employee_hej,
    phone: 20757535,
    email: 'hej@toecho.dk',
  },
  {
    name: 'Jens Væver Hartfelt',
    title: 'Fullstack udvikler',
    image: employee_jha,
    phone: false,
    email: 'jha@toecho.dk',
  },
  {
    name: 'Rikke Væver Hartfelt',
    title: 'Kommunikation',
    image: employee_rih,
    phone: false,
    email: 'rih@toecho.dk',
  },
  {
    name: 'Josef Brøndum Schmidt',
    title: 'Fullstack udvikler',
    image: employee_jbs,
    phone: false,
    email: 'jbs@toecho.dk',
  },
  {
    name: 'Sonia Johansen',
    title: 'UX designer',
    image: employee_soj,
    phone: false,
    email: 'soj@toecho.dk',
  },
  {
    name: 'Johan Bidstrup',
    title: 'Brand og marketing',
    image: employee_joh,
    phone: false,
    email: 'joh@toecho.dk',
  },
  {
    name: 'Henrik Rafn',
    title: 'Forretningudvikler',
    image: employee_her,
    phone: false,
    email: 'her@toecho.dk',
  },
]

export default employees
