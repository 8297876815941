import React from 'react'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// Components
import Hero from '../components/hero'

// Styles
import common from '../styles/common'
import colors from '../styles/colors'

// Content
import employees from '../content/employees'

// Icons
import { PhoneIcon, EmailOutlineIcon } from 'mdi-react'

const OmOsPage = () => (
  <Layout>
    <div css={componentStyles}>
      <Helmet>
        <title>Ekko App - Om os</title>
      </Helmet>
      <Hero
        title="Om os"
        subTitle="Vejen fra en god idé til en succesfuld startup"
      />
      <div className="container the-story">
        <h1 className="u_mb-md">Historien bag Ekko App</h1>
        <p className="lead">
          Med visionen om at gøre medarbejderkommunikation enkel, intuitiv og
          effektiv, startede Henrik Jensen i 2014 ToEcho. Efter mange års
          arbejde med udvikling af avancerede kommunikationsløsninger til
          medarbejdere erfarede Henrik at både store og små virksomheder havde
          behov for at kommunikere effektivt.
        </p>
        <p>
          Hver dag anvender adskillige danske virksomheder Ekko App til at
          kommunikere effektivt, til at styrke fællesskabet, til at motivere,
          til at uddanne, til at dele viden og meget mere.
        </p>
        <p>
          Vi leverer færdige brancheløsninger til detail, service og facility
          samt bygge og anlæg, men vi har også udviklet løsninger til
          traditionelle virksomheder. Finder du ikke din virksomhedstype i
          ovenstående, så kontakt os og få skræddersyet en Ekko App til jeres
          behov.
        </p>
      </div>

      <div className="container full-width employees">
        <div className="inner-container">
          <h1 className="u_mb-md">Holdet bag</h1>
          <p className="lead u_mb-md">
            Vi er et lille passioneret hold, der brænder for det vi laver.
            <br />
            Se om vi har nogle{' '}
            <Link to="/ledige-stillinger">ledige stillinger</Link> der passer
            til dig eller send os en uopfordret ansøgning til{' '}
            <a href="mailto:job@toecho.dk?subject=Uopfordret jobansøgning">
              job@toecho.dk
            </a>
          </p>
          <div className="employees-container">
            {employees.map((employee, employeeKey) => (
              <div className="employee" key={employeeKey}>
                <div>
                  <img src={employee.image} alt={employee.name} />
                </div>
                <div>
                  <h3>{employee.name}</h3>
                  <p className="lead">{employee.title}</p>
                  {employee.email && (
                    <a href={'mailto:' + employee.email}>
                      <EmailOutlineIcon />
                      {employee.email}
                    </a>
                  )}
                  {employee.phone && (
                    <a href={'tel:' + employee.phone}>
                      <PhoneIcon />
                      {employee.phone}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="background-fill" />
      </div>
    </div>
  </Layout>
)

function componentStyles() {
  return css`
    a {
      color: ${colors.seaBlue};
    }

    div.container {
      padding: 100px 0 150px 0;
    }

    div.container.the-story {
      padding-top: 150px;
    }

    div.container.employees {
      position: relative;

      .employees-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .employee {
        /* Responsive layout */
        @media screen and (min-width: ${common.breakpointLarge + 1}px) {
          flex: 0 1 49%;

          & > div {
            display: inline-block;
            vertical-align: top;
          }

          img {
            max-height: 150px;
            margin-right: 0.75rem;
          }
        }

        @media screen and (max-width: ${common.breakpointLarge}px) and (min-width: ${common.breakpointMedium +
            1}px) {
          flex: 0 1 32%;

          & > div {
            display: block;
          }

          img {
            width: 100%;
            margin-bottom: 0.75rem;
          }
        }

        @media screen and (max-width: ${common.breakpointMedium}px) and (min-width: ${common.breakpointSmall +
            1}px) {
          flex: 0 49%;

          & > div {
            display: block;
          }

          img {
            width: 100%;
            margin-bottom: 0.75rem;
          }
        }
        @media screen and (max-width: ${common.breakpointSmall}px) {
          flex: 0 100%;

          & > div {
            display: block;
          }

          img {
            width: 100%;
            margin-bottom: 0.75rem;
          }
        }

        background-color: ${colors.white};
        border: 1px ${colors.midGrey} solid;
        border-radius: 3px;
        box-shadow: ${common.boxShadow};
        padding: 1rem;
        margin-bottom: 1rem;

        img {
          border: 1px ${colors.midGrey} solid;
          border-radius: 3px;
          box-shadow: ${common.boxShadow};
        }

        p.lead {
          margin-bottom: 0.25rem;
        }

        a {
          display: block;
          text-decoration: none;
          padding: 0.25rem 0;
          margin: 0.5rem 0 0 0;

          svg {
            vertical-align: text-bottom;
            margin-right: 0.5rem;
            max-height: 1.2rem;
          }
        }
      }

      .background-fill {
        border-top: 1px ${colors.midGrey} solid;
        background-color: ${colors.ultraLightGrey};
      }
    }
  `
}

export default OmOsPage
